<template>
  <div>
    <b-card no-body class="mb-1">
      <div class="m-2">
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
            >
              <span class="text-muted"
                >Mostrando {{ start_page }} a {{ to_page }} de
                {{ total_data }} registros</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
            >
              <b-pagination
                v-model="current_page"
                :total-rows="total_data"
                :per-page="perpage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mr-1">Mostrar</label>
            <v-select
              v-model="perpage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label class="mr-2">registros</label>
            <feather-icon
              class="cursor-pointer"
              icon="RefreshCcwIcon"
              size="20"
              @click="resetData"
            />
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="6">
            <div
              :class="[
                'd-flex align-items-center justify-content-end',
                $store.getters['app/currentBreakPoint'] === 'xs'
                  ? 'flex-wrap'
                  : '',
              ]"
            >
              <b-input-group class="mr-1">
                <b-form-input
                  placeholder="Empleado..."
                  v-model="search_input"
                  debounce="500"
                />
                <b-input-group-append>
                  <b-button variant="outline-primary" @click="refreshTable">
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <b-button
                :class="[
                  $store.getters['app/currentBreakPoint'] === 'xs'
                    ? 'mt-1'
                    : '',
                ]"
                variant="primary"
                to="/administrativo/empleados/crear"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                v-if="isAdmin"
              >
                <span class="text-nowrap">Agregar empleado</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="table-responsive">
        <b-table
          :api-url="'/api/employee/get-employees'"
          ref="refUserListTable"
          class="position-relative"
          :items="myProvider"
          :stacked="currentBreakPoint === 'sm' || currentBreakPoint === 'xs'"
          sticky-header="75vh"
          :fields="arrayColumns"
          primary-key="id"
          table-class="text-nowrap"
          responsive
          show-empty
          :busy.sync="isBusy"
          empty-filtered-text="No hay registros que coincidan con tu búsqueda."
          empty-text="No hay registros que mostrar."
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="current_page"
          :per-page="perpage"
          :filter="search_input"
          small
          thead-class="b-vue-th"
          hover
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Cargando...</strong>
            </div>
          </template>
          <template #cell(photo)="data">
            <b-avatar
              size="32"
              :src="data.item.photo"
              :text="null"
              :variant="`light`"
            />
            <!-- :to="{ name: 'apps-users-view', params: { id: data.item.id } }" -->
          </template>
          <template #cell(dob)="data">
            {{ data.item.dob | myGlobalDayShort }}
          </template>
          <template #cell(start_date)="data">
            {{ data.item.start_date | myGlobalDayShort }}
          </template>
          <template #cell(salary)="data">
            <money
              class="input-form text-center"
              v-model="data.item.salary"
              v-bind="money"
              id="bank_detail"
              disabled
              style="background: transparent; border: none; color: black"
            ></money>
          </template>
          <template #cell(created_by)="data">
            <span
              >{{ data.item.created_by_name }}
              {{ data.item.created_by_last_name }}
            </span>
            <br />
            <span>{{ data.item.created_at | myGlobalDayShort }}</span>
          </template>
          <template #cell(actions)="data">
            <router-link
              :to="`/administrativo/empleados/editar/${data.item.id}`"
              v-if="isAdmin"
            >
              <feather-icon
                class="cursor-pointer mr-1 text-warning"
                icon="EditIcon"
                size="20"
                :id="`edit-tooltip${data.index}`"
              />
              <b-tooltip :target="`edit-tooltip${data.index}`" placement="top">
                Editar
              </b-tooltip>
            </router-link>
            <feather-icon
              class="cursor-pointer text-danger"
              @click="deleteEmployee(data.item.id)"
              icon="TrashIcon"
              size="20"
              :id="`delete-tooltip${data.index}`"
              v-if="isAdmin"
            />
            <b-tooltip :target="`delete-tooltip${data.index}`" placement="top">
              Eliminar
            </b-tooltip>
          </template>
        </b-table>
      </div>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Mostrando {{ start_page }} a {{ to_page }} de
              {{ total_data }} registros</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="current_page"
              :total-rows="total_data"
              :per-page="perpage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <!-- modal-class="xxl-modal" -->
    <b-modal
      title="Agregar gasto"
      v-model="modalGastoController"
      size="xl"
      ok-title="Guardar"
      @ok.prevent="saveTransaction"
    >
      <create-transaction-page
        ref="createTransactionRef"
        :type="1"
        @closeModal="closeModal"
      ></create-transaction-page>
    </b-modal>
  </div>
</template>

<script>
import { Money } from "v-money";
import vSelect from "vue-select";
export default {
  components: {
    Money,
    vSelect,
  },
  data() {
    return {
      sortBy: "first_name",
      sortDesc: false,
      arrayColumns: [
        { key: "photo", label: "Foto", class: "text-center" },
        { key: "first_name", label: "Nombre", class: "text-center" },
        { key: "last_name", label: "Apellidos", class: "text-center" },
        { key: "known_as", label: "Conocido como", class: "text-center" },
        { key: "dob", label: "DOB", class: "text-center" },
        { key: "mobile_phone", label: "Móvil", class: "text-center" },
        { key: "position", label: "Cargo", class: "text-center" },
        { key: "salary", label: "Salario", class: "text-center" },
        { key: "status", label: "Estado", class: "text-center" },
        { key: "start_date", label: "Fecha de inicio", class: "text-center" },
        { key: "created_by", label: "Creador por", class: "text-center" },
        { key: "actions", label: "Acciones", class: "text-center " },
      ],
      perPageOptions: [10, 25, 50, 100],
      //   myProvider: [],
      search_input: "",
      orderby: this.dato2 == null ? 3 : this.dato2,
      order: this.dato1 == null ? "desc" : this.dato1,
      start_page: "",
      end_page: "",
      total_data: "",
      perpage: 1000,
      next_page: "",
      current_page: 1,
      to_page: "",
      dato1: "",
      dato2: "",
      from: null,
      to: null,
      loadingData: false,
      isBusy: false,
      modalGastoController: false,
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "S/. ",
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    resetData() {
      (this.search_input = ""),
        (this.orderby = this.dato2 == null ? 3 : this.dato2),
        (this.order = this.dato1 == null ? "desc" : this.dato1),
        (this.start_page = ""),
        (this.end_page = ""),
        (this.total_data = ""),
        (this.perpage = 100),
        (this.next_page = ""),
        (this.current_page = 1),
        (this.to_page = ""),
        (this.dato1 = ""),
        (this.dato2 = "");
      this.from = null;
      this.to = null;
      this.$refs.refUserListTable.refresh();
    },
    closeModal() {
      this.modalGastoController = false;
      this.$refs.refUserListTable.refresh();
    },
    refreshTable() {
      this.$refs.refUserListTable.refresh();
    },
    myProvider(ctx) {
      const promise = axios.post(
        // "/some/url?page=" + ctx.currentPage + "&size=" + ctx.perPage
        `${ctx.apiUrl}?page=${ctx.currentPage}`,
        {
          orderby: ctx.sortDesc,
          order: ctx.sortBy == "" ? "first_name" : ctx.sortBy,
          campo: ctx.filter,
          perpage: ctx.perPage,
        }
      );
      // Must return a promise that resolves to an array of items
      return promise.then((data) => {
        // Pluck the array of items off our axios response
        const items = data.data.data;
        this.start_page = data.data.from;
        this.current_page = data.data.current_page;
        this.perpage = data.data.per_page;
        this.next_page = this.start_page + 1;
        this.end_page = data.data.last_page;
        this.total_data = data.data.total;
        this.to_page = data.data.to;
        // Must return an array of items or an empty array if an error occurred
        return items || [];
      });
    },
    saveTransaction() {
      this.$refs.createTransactionRef.upload(2);
    },
    showToast(variant, position, title, icon, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            variant,
          },
        },
        {
          position,
        }
      );
    },
    deleteEmployee(employee) {
      this.$swal({
        title: "Estas seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
        customClass: {
          cancelButton: "btn btn-outline-danger ml-1",
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios
            .post("/api/employee/delete", {
              deleted_by: this.currentUser.user_id,
              employee_id: employee,
            })
            .then((res) => {
              this.showToast(
                "success",
                "top-right",
                "Felicidades",
                "CheckIcon",
                "Empleado eliminado!"
              );
              this.refreshTable();
            });
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
